/* width */
.product-detail-img-div::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.product-detail-img-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.product-detail-img-div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.product-detail-img-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1024px) {
  .product-detail-img-div img {
    width: 100%;
  }
}
.product-detail-img-div figure {
  width: 100%;
}
