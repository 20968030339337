/*---------EMPRESA-------*/
.historia span {
  font: 700 28px/28px 'Lato', sans-serif;
  color: #37900a;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 25px 0;
}

.historia h2 {
  font: 700 56px 'Droid Serif', serif;
  color: #222;
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.historia p {
  color: #666;
}
.empresa-imgs {
  font-size: inherit;
}
.empresa-imgs .gallery {
  border: 12px solid black;
  width: 100%;
  height: 350px;
}
.empresa-imgs img {
  transition: 0.4s linear;
  cursor: pointer;
}
.empresa-imgs .gallery .black-1 {
  position: absolute;
  top: -50px;
  left: 67px;
  height: 350px;
  width: 320px;
  opacity: 0;
  background-color: black;
  z-index: 4;
  transition: 0.4s linear;
}
.empresa-imgs .gallery .black-2 {
  position: absolute;
  top: 70px;
  left: 350px;
  height: 350px;
  width: 320px;
  opacity: 0;
  background-color: black;
  z-index: 8;
  transition: 0.4s linear;
}
.empresa-imgs .gallery .black-1:hover {
  opacity: 0.4;
}
.empresa-imgs .gallery .black-2:hover {
  opacity: 0.4;
}
/*.black-2::after {
  content: "";
  display: block;
  position: absolute;
  width: 320px;
  height: 350px;
  background-color: white;
}*/
.empresa-imgs .gallery .img-1 {
  position: absolute;
  top: -50px;
  left: 67px;
  box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.empresa-imgs .gallery .img-2 {
  position: absolute;
  top: 70px;
  left: 350px;
  box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
  z-index: 5;
}

/*-----LOADING SPINNER------*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #678269 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*POST-HTML*/
.post-html iframe {
  width: 100%;
  min-height: 100vh;
}

@media (max-width: 425px) {
  .post-html iframe {
    min-height: 200px;
  }
}

@media (min-width: 1025px) {
 
  
}
