.image-wrapper {
  border: 12px solid black;
  width: fit-content;
  padding: 13px;
  transition: border-color 0.4s linear;
}
.image-wrapper:hover {
  border-color: #37900a;
}
.image-wrapper:hover.image-wrapper::after {
  border-color: #37900a;
}
.image-wrapper:hover.image-wrapper::before {
  border-color: #37900a;
}
.image-wrapper::after {
  content: "";
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  transition: 0.4s linear;
  transform: rotate(-45deg);
  background-color: white;
  border-bottom: 12px solid black;
}

.image-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  border-top: 12px solid black;
  background-color: white;
  transform: rotate(-45deg);
  transition: 0.4s linear;
}

.right::after {
  top: -42px;
  left: -10px;
}

.right::before {
  left: 538px;
  top: 271px;
}

.left::after {
  top: -41px;
  left: -42px;
}

.left::before {
  left: 506px;
  top: 271px;
}

/*LEFT top: 260px;*/
/*left: 486px;*/

/*RIGHT  
left: 518px;
top: 260px;
*/