.welcome h2 {
  font-size: 38px;
  color: #222;
  display: block;
  padding: 0 0 0 0;
}
.welcome h3 {
  color: #222;
  display: block;
  font-size: 34px;
  font-weight: 500;
}
.welcome h3:last-child {
  font-size: 22px;
  color: #3a695a;
  margin-top: 10px;
}
.home-imgs .gallery {
  border: 12px solid black;
  width: 100%;
  min-width: 250px;
  height: 350px;
}
.home-imgs .gallery img {
  position: absolute;
  -webkit-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.3);
}
.home-imgs .gallery .img-1 {
  top: -100px;
  top: -50px;
  left: 67px;
  z-index: 1;
}
.home-imgs .gallery .img-2 {
  top: 70px;
  left: 350px;
  z-index: 3;
}
.home-imgs .gallery .black-1 {
  position: absolute;
  top: -50px;
  left: 67px;
  height: 350px;
  width: 320px;
  opacity: 0;
  background-color: black;
  z-index: 2;
  transition: 0.4s linear;
}
.home-imgs .gallery .black-2 {
  position: absolute;
  top: 70px;
  left: 350px;
  height: 350px;
  width: 320px;
  opacity: 0;
  background-color: black;
  z-index: 4;
  transition: 0.4s linear;
}
.home-imgs .gallery .black-1:hover {
  opacity: 0.4;
}
.home-imgs .gallery .black-2:hover {
  opacity: 0.4;
}
