/*------FOOTER-----*/
.quem-somos p {
  font-size: 13px;
  color: #ccc;
  font-weight: 600;
}
.quem-somos h2 {
  font-size: 15px;
  color: white;
}
.utilidades h2 {
  color: white;
  font-size: 15px;
  text-transform: uppercase;
}
.utilidades a {
  font-size: 13px;
  color: #ccc;
  font-weight: 600;
  margin-bottom: 1rem;
}
.utilidades a:hover {
  color: white;
}
.sobre-nos {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.utilidades {
  border-bottom: 1px solid #ccc;
}
.all-rights {
  color: #ccc;
}
.all-rights a {
  transition: 0.2s linear;
}
.all-rights a:hover {
  letter-spacing: 1.5px;
  color: white;
}
.black-img {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  height: 100%;
  content: '';
  background-color: #000000a1;
}
